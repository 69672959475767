






















































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import { Plan } from "@/types";
import { getAuthToken, setAuth } from "@/util/auth";
import vendorPlanStoreModule from "@/store/modules/vendorPlan";
import planStoreModule from "@/store/modules/plan";

const { mapActions: vendorPlanActions } =
  createNamespacedHelpers("VENDOR_PLAN");

const { mapActions: planActions, mapGetters: planGetters } =
  createNamespacedHelpers("PLAN");

export default Vue.extend<any, any, any, any>({
  name: "Plan",
  beforeRouteEnter: (from, to, next) => {
    next((vm) => {
      const { t: token } = vm.$route.query;

      setAuth({ token: `${token}` }, false);
      next();
    });
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    valid: false,
    plan: undefined as undefined | Plan,
  }),
  created() {
    this.fetchPlanList();
  },
  mounted() {
    if (this.$route.query.message) {
      const msg =
        "Your current plan does not permit you to access that resource.";
      this.$store.dispatch("setToast", {
        title: "Plan required",
        type: "success",
        timeout: 4500,
        text: msg,
      });
    }
  },
  computed: {
    ...planGetters(["planPage"]),
  },
  methods: {
    ...planActions(["fetchPlanList"]),
    ...vendorPlanActions(["createVendorPlan"]),
    selectPlan() {
      const valid = !!this.plan;

      if (valid) {
        this.createVendorPlan({
          planId: this.plan?._id,
          businessId: this.businessId,
        }).then((plan) => {
          if (plan) {
            this.$store.dispatch("login", {
              token: getAuthToken(),
              url: "/",
            });
          }
        });
      }
    },
    selectCard(plan: Plan) {
      this.plan = plan;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("PLAN")) {
      this.$store.registerModule("PLAN", planStoreModule);
    }
    if (!this.$store.hasModule("VENDOR_PLAN")) {
      this.$store.registerModule("VENDOR_PLAN", vendorPlanStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("PLAN");
    this.$store.unregisterModule("VENDOR_PLAN");
  },
});
