import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Plan, Page } from "@/types";

type PlanState = {
  planPage: Page<Plan>;
};

const plan: Module<PlanState, unknown> = {
  namespaced: true,
  state: () => ({
    planPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getPlan: (state) => (planId: string) =>
      state.planPage.docs.find((c) => c._id === planId),
    planPage: (state) => state.planPage,
  },
  mutations: {
    ADD_PLAN: (state, _plan) => {
      let idx = -1;
      state.planPage.docs.map((r, i) => {
        if (r._id === _plan._id) idx = i;
      });
      if (idx === -1) state.planPage.docs.push(_plan);
      else Vue.set(state.planPage.docs, idx, _plan);
    },
    SET_PLAN_PAGE: (state, list) => {
      state.planPage = list;
    },
    REMOVE_PLAN(state, plan) {
      let idx = -1;
      state.planPage.docs.map((r, i) => {
        if (r._id === plan._id) idx = i;
      });
      if (idx > -1) state.planPage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchPlan(context, params = "") {
      api
        .get(`/v1/plan${params}`)
        .then((response) => {
          context.commit("ADD_PLAN", response.data.plan);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchPlanList(context, params = "") {
      api
        .get(`/v1/plan${params}`)
        .then((response) => {
          context.commit("SET_PLAN_PAGE", response.data.planPage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default plan;
